.react-calendar {
  background: #fff;
  border: 1px solid #a0a096;
  width: 350px;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  margin: -.5em;
  display: flex;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: .5em;
}

.react-calendar, .react-calendar *, .react-calendar :before, .react-calendar :after {
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
  margin: 0;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
}

.react-calendar__navigation button {
  background: none;
  min-width: 44px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: .75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  justify-content: center;
  align-items: center;
  font-size: .75em;
  font-weight: bold;
  display: flex;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 2em .5em;
}

.react-calendar__tile {
  text-align: center;
  background: none;
  max-width: 100%;
  padding: 10px 6.6667px;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: #fff;
  background: #006edc;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/*# sourceMappingURL=index.8a4508e4.css.map */
